'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, CheckCircle } from 'lucide-react'
import axiosInstance from '../axiosInstance'

interface RequestModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function RequestModal({ isOpen, onClose }: RequestModalProps) {
  const [requestType, setRequestType] = useState<'movie' | 'message'>('movie')
  const [content, setContent] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const endpoint = requestType === 'movie' ? '/api/request' : '/api/contact'
      await axiosInstance.post(endpoint, { content })
      setIsSubmitted(true)
      setContent('')
      setTimeout(() => {
        setIsSubmitted(false)
        onClose()
      }, 3000)
    } catch (error) {
      console.error('Error submitting:', error)
      alert('Failed to submit. Please try again.')
    }
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-gray-800 p-6 rounded-lg w-full max-w-md shadow-xl"
            onClick={(e) => e.stopPropagation()}
          >
            {isSubmitted ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="text-center py-8"
              >
                <CheckCircle className="mx-auto mb-4 text-green-500" size={48} />
                <h2 className="text-2xl font-bold text-white mb-2">Thank You!</h2>
                <p className="text-gray-300">
                  {requestType === 'movie'
                    ? "Your request has been submitted successfully. We'll work on it!"
                    : "Your message has been sent. We'll get back to you soon!"}
                </p>
              </motion.div>
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold gradient-text">
                    {requestType === 'movie' ? 'Request Content' : 'Send Message'}
                  </h2>
                  <button onClick={onClose} className="text-gray-400 hover:text-white">
                    <X size={24} />
                  </button>
                </div>
                <div className="mb-4">
                  <button
                    onClick={() => setRequestType('movie')}
                    className={`mr-2 px-4 py-2 rounded ${
                      requestType === 'movie' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                    }`}
                  >
                    Request Movie
                  </button>
                  <button
                    onClick={() => setRequestType('message')}
                    className={`px-4 py-2 rounded ${
                      requestType === 'message' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                    }`}
                  >
                    Send Message
                  </button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder={
                      requestType === 'movie'
                        ? "What movie, series, or anime would you like to see?"
                        : "Enter your message here"
                    }
                    className="w-full h-32 p-3 bg-gray-700 text-white rounded-md resize-none focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    required
                  />
                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      onClick={onClose}
                      className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      {requestType === 'movie' ? 'Submit Request' : 'Send Message'}
                    </button>
                  </div>
                </form>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}