'use client'

import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Home, Search, RefreshCcw } from 'lucide-react'
import { Link } from 'react-router-dom'

const movieQuotes = [
  "I'm going to make him an offer he can't refuse. - The Godfather",
  "May the Force be with you. - Star Wars",
  "You talking to me? - Taxi Driver",
  "E.T. phone home. - E.T. the Extra-Terrestrial",
  "Here's looking at you, kid. - Casablanca",
  "I'll be back. - The Terminator",
  "You can't handle the truth! - A Few Good Men",
  "I see dead people. - The Sixth Sense",
  "Keep your friends close, but your enemies closer. - The Godfather Part II",
  "I'm king of the world! - Titanic"
]

export default function NotFound() {
  const [quote, setQuote] = useState('')

  useEffect(() => {
    setQuote(movieQuotes[Math.floor(Math.random() * movieQuotes.length)])
  }, [])

  const refreshQuote = () => {
    let newQuote
    do {
      newQuote = movieQuotes[Math.floor(Math.random() * movieQuotes.length)]
    } while (newQuote === quote)
    setQuote(newQuote)
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-pink-900 flex flex-col items-center justify-center text-white p-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <h1 className="text-6xl md:text-8xl font-bold mb-4">404</h1>
        <h2 className="text-2xl md:text-4xl font-semibold mb-6">Page Not Found</h2>
        <p className="text-lg md:text-xl mb-8">Oops! Looks like this scene didn't make the final cut.</p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg p-6 mb-8 max-w-md w-full"
      >
        <p className="text-lg italic mb-4">"{quote}"</p>
        <button
          onClick={refreshQuote}
          className="flex items-center justify-center w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          <RefreshCcw size={18} className="mr-2" />
          New Quote
        </button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        className="flex flex-col sm:flex-row gap-4"
      >
        <Link to="/" className="flex items-center justify-center bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-6 rounded transition duration-300">
          <Home size={18} className="mr-2" />
          Back to Home
        </Link>
        <Link href="/search" className="flex items-center justify-center bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded transition duration-300">
          <Search size={18} className="mr-2" />
          Search Movies
        </Link>
      </motion.div>

      <motion.footer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.9, duration: 0.5 }}
        className="mt-12 text-center text-sm text-gray-400"
      >
        © 2024 moviesworld.store | All rights reserved
      </motion.footer>
    </div>
  )
}