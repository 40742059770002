import React from 'react'
import { Link } from 'react-router-dom'
import { Facebook, Twitter, Instagram, Youtube } from 'lucide-react'

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="bg-gray-900 text-gray-300 py-8 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">About Us</h3>
            <p className="text-sm">
              Moviesworld.store is a content aggregator that collects links from various third-party websites. We do not host or upload any copyrighted material. All content is the responsibility of the respective third-party sources.
            </p>
          </div>
          
        <div className="mt-8 pt-8 border-t border-gray-800 text-center">
          <p className="text-sm">
            © {currentYear} Moviesworld.store Official Website. All Rights Reserved.
          </p>
        </div>
        </div>
      </div>
    </footer>
  )
}