import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import Footer from './components/footer.js';
import NotFound from './components/Notfound.js';
import './App.css';

// Use React.lazy to load components lazily
const Home = lazy(() => import('./Pages/Home.js'));
const Anime = lazy(() => import('./Pages/anime.js'));
const Series = lazy(() => import('./Pages/Series'));
const Movies = lazy(() => import('./Pages/Movies'));
const Kdrama = lazy(() => import('./Pages/Kdrama'));
const Desipron = lazy(() => import('./Pages/Desipron'));
const Moviesdetail = lazy(() => import('./Pages/MoviesDetail.js'));
const DesiPronPage = lazy(() => import('./Pages/Desiprondetail.js'));

function App() {
  return (
    <Router>
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/anime" element={<Anime />} />
          <Route path="/series" element={<Series />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/kdrama" element={<Kdrama />} />
          <Route path="/desipron" element={<Desipron />} />
          <Route path="/movie/:slug" element={<Moviesdetail />} />
          <Route path="/adultDetail/:slug" element={<DesiPronPage />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
