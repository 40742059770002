'use client'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaHome, FaTv, FaFilm, FaGlobe, FaExclamationTriangle } from 'react-icons/fa'
import RequestModal from './RequestModal.tsx'

const NavItem = ({ icon, label, to, special }) => (
  <Link to={to} className={`flex flex-col items-center text-sm ${special ? 'text-red-500' : 'text-white'} no-underline`}>
    {React.cloneElement(icon, { size: 24 })}
    <span>{label}</span>
  </Link>
)

export default function Navbar() {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 bg-gray-800 flex justify-around p-4 shadow-lg z-40"> {/* Changed z-index to 40 */}
        <NavItem icon={<FaHome />} label="Home" to="/" />
        <NavItem icon={<FaTv />} label="Anime" to="/anime" />
        <NavItem icon={<FaTv />} label="Series" to="/series" />
        <NavItem icon={<FaFilm />} label="Movies" to="/movies" />
        <NavItem icon={<FaGlobe />} label="Kdrama" to="/kdrama" />
        <NavItem icon={<FaExclamationTriangle />} label="18+" to="/desipron" special />
      </nav>
      <RequestModal isOpen={isRequestModalOpen} onClose={() => setIsRequestModalOpen(false)} />
    </>
  )
}